import React from 'react';
import cx from 'classnames';

import {TextHeading, Text, FontVariant} from '@pexip/components';

import styles from './BreakoutRoomsNameAndTimeLeft.module.scss';

export const BreakoutRoomsNameAndTimeLeftView: React.FC<{
    isSmallText?: boolean;
    isMultiline?: boolean;
    roomName: string;
    timeLeft: string;
}> = ({isMultiline = false, isSmallText = false, roomName, timeLeft}) => (
    <div className={cx(styles.container, {[styles.isMultiline]: isMultiline})}>
        <div className={styles.roomName}>
            <TextHeading
                htmlTag="h5"
                fontVariant={isSmallText ? FontVariant.Body : FontVariant.H5}
            >
                {roomName}
            </TextHeading>
        </div>
        {timeLeft && (
            <Text
                className={cx('ml-2', styles.timeLeft)}
                fontVariant={isSmallText ? FontVariant.Body : FontVariant.H6}
            >
                {isMultiline ? timeLeft : `/ ${timeLeft}`}
            </Text>
        )}
    </div>
);

export type BreakoutRoomsNameAndTimeLeftViewProps = React.ComponentProps<
    typeof BreakoutRoomsNameAndTimeLeftView
>;
