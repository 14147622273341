import type {Channel} from '@pexip/plugin-api';

import {callSignals} from '../signals/Call.signals';
import {infinityClientSignals} from '../signals/InfinityClient.signals';

export const registerInfinityClientSignals = (
    channels: Map<string, Channel>,
) => [
    infinityClientSignals.onParticipants.add(payload => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:participants',
                payload,
            });
        });
    }),
    infinityClientSignals.onParticipantLeft.add(payload => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:participantLeft',
                payload,
            });
        });
    }),
    infinityClientSignals.onParticipantJoined.add(payload => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:participantJoined',
                payload,
            });
        });
    }),
    infinityClientSignals.onRaiseHand.add(payload => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:raiseHand',
                payload,
            });
        });
    }),
    infinityClientSignals.onConferenceStatus.add(payload => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:conferenceStatus',
                payload,
            });
        });
    }),
    infinityClientSignals.onAuthenticatedWithConference.add(context => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:conference:authenticated',
                payload: context,
            });
        });
    }),
    infinityClientSignals.onConnected.add(connected => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:connected',
                payload: connected,
            });
        });
    }),
    infinityClientSignals.onDisconnected.add(disconnected => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:disconnected',
                payload: disconnected,
            });
        });
    }),
    infinityClientSignals.onMe.add(payload => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:me',
                payload,
            });
        });
    }),
    infinityClientSignals.onMessage.add(msg => {
        const {direct, ...payload} = msg;
        channels.forEach(channel => {
            channel.sendEvent({
                event: direct ? 'event:directMessage' : 'event:message',
                payload,
            });
        });
    }),
    infinityClientSignals.onApplicationMessage.add(applicationMessage => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:applicationMessage',
                payload: applicationMessage,
            });
        });
    }),
    infinityClientSignals.onTransfer.add(({alias}) => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:transfer',
                payload: {alias},
            });
        });
    }),
    infinityClientSignals.onCancelTransfer.add(cancelTransfer => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:cancelTransfer',
                payload: cancelTransfer,
            });
        });
    }),
    infinityClientSignals.onStage.add(stage => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:stage',
                payload: stage,
            });
        });
    }),
    callSignals.onPresentationConnectionChange.add(state => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:presentationConnectionStateChange',
                payload: state,
            });
        });
    }),
    infinityClientSignals.onLayoutUpdate.add(layout => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:layoutUpdate',
                payload: layout,
            });
        });
    }),
    infinityClientSignals.onBreakoutBegin.add(breakout => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:breakoutBegin',
                payload: breakout,
            });
        });
    }),
    infinityClientSignals.onBreakoutEnd.add(breakout => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:breakoutEnd',
                payload: breakout,
            });
        });
    }),
    infinityClientSignals.onBreakoutRefer.add(breakout => {
        channels.forEach(channel => {
            channel.sendEvent({
                event: 'event:breakoutRefer',
                payload: breakout,
            });
        });
    }),
];
