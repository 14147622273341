import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonProps} from '@pexip/components';
import {BadgeCounter, Icon, IconTypes} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {InMeetingInputControl} from '../InputControl/InMeetingInputControl.view';

export const ChatPanelToggleView: React.FC<
    ButtonProps & {
        newMessagesCount: number;
        isPanelOpen: boolean;
        isTouchDevice: boolean;
    }
> = ({isPanelOpen, newMessagesCount, isTouchDevice, ...props}) => {
    const {t} = useTranslation();
    return (
        <BadgeCounter
            number={0}
            isVisible={!isPanelOpen && newMessagesCount > 0}
            isTruncated
        >
            <InMeetingInputControl
                shouldShowTooltip={!isTouchDevice}
                tooltipPosition="right"
                tooltipText={t('meeting.toggle-chat-tooltip', 'Chat')}
                aria-label={t('meeting.toggle-chat', 'Toggle chat panel')}
                data-testid={TestId.ButtonChat}
                icon={<Icon source={IconTypes.IconChat} size={'small'} />}
                isActive={isPanelOpen}
                {...props}
            />
        </BadgeCounter>
    );
};
export type ChatPanelToggleProps = React.ComponentProps<
    typeof ChatPanelToggleView
>;
