import {useEffect, useState} from 'react';

import {
    createMedia,
    createAudioStreamProcess,
    createVideoStreamProcess,
    createAudioMixingProcess,
    AUDIO_CONTENT_HINTS,
    VIDEO_CONTENT_HINTS,
    applyContentHint,
    createGetDisplayMedia,
    isInitial,
} from '@pexip/media';
import {isSendingAudio, isSendingVideo} from '@pexip/infinity';
import type {
    AudioContentHint,
    Media,
    MediaProcessor,
    Segmenters,
    VideoStreamTrackProcessorAPIs,
} from '@pexip/media';
import {denoiseWasm} from '@pexip/denoise/urls';
import type {RenderEffects} from '@pexip/media-processor';
import {
    urls as mpUrls,
    createSegmenter,
    createCanvasTransform,
} from '@pexip/media-processor';
import {isEmpty} from '@pexip/utils';
import {
    StreamQuality,
    createPreviewAudioInputHook,
    createPreviewAudioOutputHook,
    createPreviewControllerHook,
    createPreviewHook,
    createPreviewVideoInputHook,
    currentBrowserName,
    generateMediaSignalHooks,
    qualityToMediaConstraints,
} from '@pexip/media-components';
import type {
    MediaDeviceInfoLike,
    InputConstraintSet,
} from '@pexip/media-control';
import {
    interpretCurrentFacingMode,
    areMultipleFacingModeSupported,
    toMediaDeviceInfo,
} from '@pexip/media-control';

import {
    config,
    defaultUserConfig,
    shouldEnableVideoProcessing,
} from '../config';
import {mediaSignals} from '../signals/Media.signals';
import {userCustomImageSignal} from '../signals/ImageStore.signals';
import {logger} from '../logger';
import {
    FFT_SIZE,
    UPDATE_FREQUENCY_HZ,
    SILENT_DETECTION_DURATION_S,
    VAD_THROTTLE_MS,
    PROCESSING_WIDTH,
    PROCESSING_HEIGHT,
    SETTINGS_PROCESSING_WIDTH,
    SETTINGS_PROCESSING_HEIGHT,
    FRAME_RATE,
    BACKGROUND_BLUR_AMOUNT,
    FOREGROUND_THRESHOLD,
    EDGE_BLUR_AMOUNT,
    RENDER_EFFECTS,
    BG_IMAGE_URL,
    MASK_COMBINE_RATIO,
} from '../constants';
import {applicationConfig} from '../applicationConfig';
import {
    endPresentationSignal,
    presentationStreamSignal,
} from '../signals/Meeting.signals';

import {imageStore} from './Image.service';

const audioProcessor = createAudioStreamProcess({
    shouldEnable: () => applicationConfig.audioProcessing,
    denoiseParams: {
        wasmURL: denoiseWasm.href,
        workletModule: mpUrls.denoise().href,
    },
    fftSize: FFT_SIZE,
    analyzerUpdateFrequency: UPDATE_FREQUENCY_HZ,
    audioSignalDetectionDuration: SILENT_DETECTION_DURATION_S,
    throttleMs: VAD_THROTTLE_MS,
    onAudioSignalDetected: mediaSignals.onSilentDetected.emit,
    onVoiceActivityDetected: mediaSignals.onVAD.emit,
});

/**
 * NavigatorUABrandVersion interface
 * @see {@link https://wicg.github.io/ua-client-hints/#dictdef-navigatoruabrandversion}
 */
interface NavigatorUABrandVersion {
    brand: string;
    version: string;
}
/**
 * NavigatorUAData interface
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData}
 */
interface NavigatorUAData {
    brands: NavigatorUABrandVersion[];
    mobile: boolean;
    platform: string;
}

const isChromium = () => {
    if ('userAgentData' in navigator) {
        const {brands} = navigator.userAgentData as NavigatorUAData;
        return Boolean(brands.find(({brand}) => brand === 'Chromium'));
    }
    return false;
};

const taskVisionURL = new URL(
    './assets/@mediapipe/tasks-vision/wasm/',
    document.baseURI,
);

const taskVisionBasePath = taskVisionURL.pathname;
const selfieSegmenterURL = new URL(
    './assets/@mediapipe/models/selfie_segmenter_landscape.tflite',
    document.baseURI,
);
const modelAsset = {
    path: selfieSegmenterURL.pathname,
    modelName: 'selfie' as const,
};
// midiapipe-task-vision does not support GPU delegate in
// WebWorker for Firefox or Safari or iOS
// See https://github.com/google/mediapipe/issues/4501#issuecomment-1728983202
const delegate = () =>
    isChromium() ? config.get('videoProcessingDelegate') : 'CPU';
const selfie = createSegmenter(taskVisionBasePath, {
    processingWidth: PROCESSING_WIDTH,
    processingHeight: PROCESSING_HEIGHT,
    modelAsset,
    delegate,
});
export const mainSegmenters: Partial<Segmenters> = {
    selfie,
};

const chooseVideoProcessorAPI = (): VideoStreamTrackProcessorAPIs => {
    // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData
    // userAgentData is currently only available in Chromium as well as the
    // `MediaStreamTrackProcessor` API
    const videoProcessingAPI = config.get('videoProcessingAPI');
    if (videoProcessingAPI === 'stream' || videoProcessingAPI === 'canvas') {
        return videoProcessingAPI;
    }
    if ('userAgentData' in navigator) {
        const {platform} = navigator.userAgentData as NavigatorUAData;
        // Avoid using the API to workaround the browser bug introduced from M108
        if (platform === 'Windows') {
            return 'canvas';
        }
        return 'stream';
    }
    // For other browsers, `MediaStreamTrackProcessor` API is not available
    return 'canvas';
};

export const browserSupportsPtzConstraints = () => {
    const browserSupports = navigator.mediaDevices.getSupportedConstraints();
    return (
        'pan' in browserSupports &&
        'tilt' in browserSupports &&
        'zoom' in browserSupports
    );
};

const supportingVideoProcessing = shouldEnableVideoProcessing();

const renderParams = {
    backgroundBlurAmount: BACKGROUND_BLUR_AMOUNT,
    foregroundThreshold: FOREGROUND_THRESHOLD,
    edgeBlurAmount: EDGE_BLUR_AMOUNT,
    videoSegmentation: RENDER_EFFECTS,
    maskCombineRatio: MASK_COMBINE_RATIO,
    backgroundImageUrl: BG_IMAGE_URL,
    selfManageSegmenter: supportingVideoProcessing,
};

const transformer = createCanvasTransform(selfie, renderParams);

const videoProcessor =
    supportingVideoProcessing &&
    createVideoStreamProcess({
        trackProcessorAPI: chooseVideoProcessorAPI,
        shouldEnable: () => applicationConfig.videoProcessing,
        onError: error => {
            logger.error({error}, 'Failed to process video');
        },
        segmenters: mainSegmenters,
        transformer,
        processingWidth: PROCESSING_WIDTH,
        processingHeight: PROCESSING_HEIGHT,
        frameRate: FRAME_RATE,
        ...renderParams,
    });

export const getDisplayMedia = createGetDisplayMedia(() => ({
    video: {
        displaySurface: config.get('displaySurface'),
        cursor: config.get('curosrCapture'),
    },
    audio: config.get('captureAudio'),
    surfaceSwitching: config.get('surfaceSwitching'),
    monitorTypeSurfaces: applicationConfig.monitorTypeSurfaces,
    selfBrowserSurface: applicationConfig.selfBrowserSurface,
    systemAudio: applicationConfig.systemAudio,
}));

let currentDisplayMedia: MediaStream | undefined;
export const getCurrentDisplayMedia = () => currentDisplayMedia;

/**
 * Derive the audio content hint based on the presentation MediaStream and the
 * preference from the user.
 *
 * @param stream - The MediaStream for the presentation, e.g. what you get from
 * `getDisplayMedia`
 */
export const deriveAudioContentHintFromPreso = (
    stream: MediaStream | undefined,
) => {
    const contentHint =
        stream &&
        stream.getAudioTracks().length > 0 &&
        config.get('presoContentHint') === VIDEO_CONTENT_HINTS.Motion
            ? AUDIO_CONTENT_HINTS.Music
            : defaultUserConfig.audioContentHint;
    return contentHint;
};

/**
 * What audio features to turn on based on the audio content hint
 *
 * @param hint - Audio content hint
 * @returns audio constraints e.g. echoCancellation, autoGainControl, noiseSuppression & denoise.
 */
export const deriveAudioFeaturesFromAudioContentHint = (
    hint: AudioContentHint,
) => {
    switch (hint) {
        case 'speech': {
            return {
                echoCancellation: config.get('echoCancellation'),
                autoGainControl: config.get('autoGainControl'),
                noiseSuppression:
                    config.get('noiseSuppression') || !config.get('denoise'),
                denoise: config.get('denoise'),
            };
        }
        case 'music': {
            // For an audio track with the value "music", and for constraints
            // echoCancellation, autoGainControl and noiseSuppression apply a default of "false".
            // https://www.w3.org/TR/mst-content-hint/#behavior-of-a-mediastreamtrack
            return {
                echoCancellation: false,
                autoGainControl: false,
                noiseSuppression: false,
                denoise: false,
            };
        }
        default: {
            return {
                echoCancellation: config.get('echoCancellation'),
                autoGainControl: config.get('autoGainControl'),
                noiseSuppression:
                    config.get('noiseSuppression') || !config.get('denoise'),
                denoise: config.get('denoise'),
            };
        }
    }
};

export const setCurrentDisplayMedia = (newDisplayMedia?: MediaStream) => {
    currentDisplayMedia = newDisplayMedia;
    const [audioTrack] = newDisplayMedia?.getAudioTracks() ?? [];
    if (audioTrack) {
        presentationStreamSignal.emit(newDisplayMedia);
        newDisplayMedia
            ?.getVideoTracks()
            .forEach(applyContentHint(config.get('presoContentHint')));

        // if the presentation stream has attached audio, set contentHint to "music"
        const contentHint = deriveAudioContentHintFromPreso(newDisplayMedia);
        void mediaService.media.applyConstraints({
            audio: {
                mixWithAdditionalMedia: true,
                ...deriveAudioFeaturesFromAudioContentHint(contentHint),
                contentHint,
            },
        });
    }
};
const presentationMixer = createAudioMixingProcess(getCurrentDisplayMedia);

const isMediaProcessor = (t: false | MediaProcessor): t is MediaProcessor => {
    if (!t) {
        return false;
    }
    return true;
};
const mediaProcessors = [
    videoProcessor,
    audioProcessor,
    presentationMixer,
].filter(isMediaProcessor);

const getFacingMode = (isUserFacing: boolean) =>
    isUserFacing ? 'user' : 'environment';
const isUserFacingMode = (mode: string) => mode === 'user';

const getDefaultConstraints = () => {
    const callType = config.get('callType');
    const requestAudio = isSendingAudio(callType);
    const requestVideo = isSendingVideo(callType);
    const audio: InputConstraintSet | false = requestAudio
        ? {
              sampleRate: 48000,
              echoCancellation: config.get('echoCancellation'),
              autoGainControl: config.get('autoGainControl'),
              noiseSuppression:
                  !config.get('denoise') && config.get('noiseSuppression'),
              denoise: config.get('denoise'),
              vad: config.get('vad'),
              asd: config.get('asd'),
              contentHint: config.get('audioContentHint'),
              ...(isEmpty(config.get('audioInput'))
                  ? {}
                  : {device: config.get('audioInput')}),
          }
        : false;
    const fecc = config.get('fecc');
    const video: InputConstraintSet | false = requestVideo
        ? {
              ...qualityToMediaConstraints(getStreamQuality()),
              foregroundThreshold: config.get('foregroundThreshold'),
              backgroundBlurAmount: config.get('backgroundBlurAmount'),
              edgeBlurAmount: config.get('edgeBlurAmount'),
              maskCombineRatio: config.get('maskCombineRatio'),
              frameRate: applicationConfig.frameRate,
              videoSegmentation: config.get('segmentationEffects'),
              videoSegmentationModel: applicationConfig.segmentationModel,
              backgroundImageUrl: config.get('bgImageUrl'),
              facingMode: getFacingMode(config.get('isUserFacing')),
              resizeMode: 'none',
              contentHint: config.get('videoContentHint'),
              ...(isEmpty(config.get('videoInput'))
                  ? {}
                  : {device: config.get('videoInput')}),
              ...(!browserSupportsPtzConstraints()
                  ? {}
                  : {pan: fecc, tilt: fecc, zoom: fecc}),
          }
        : false;
    return {audio, video};
};

export const mediaService = createMedia({
    getMuteState: () => ({
        audio: config.get('isAudioInputMuted'),
        video: config.get('isVideoInputMuted'),
    }),
    signals: mediaSignals,
    mediaProcessors,
    getDefaultConstraints,
});

userCustomImageSignal.add(record => {
    if (!record) {
        return;
    }
    transformer.backgroundImage = imageStore.getBitmapRecord();
});

config.subscribe('isAudioInputMuted', isMuted =>
    mediaService.media.muteAudio(isMuted),
);
config.subscribe('isVideoInputMuted', isMuted =>
    mediaService.media.muteVideo(isMuted),
);
config.subscribe('backgroundBlurAmount', backgroundBlurAmount => {
    void mediaService.media.applyConstraints({video: {backgroundBlurAmount}});
});
config.subscribe('foregroundThreshold', foregroundThreshold => {
    void mediaService.media.applyConstraints({video: {foregroundThreshold}});
});
config.subscribe('edgeBlurAmount', edgeBlurAmount => {
    void mediaService.media.applyConstraints({video: {edgeBlurAmount}});
});
config.subscribe('bgImageUrl', backgroundImageUrl => {
    void mediaService.media.applyConstraints({
        video: {backgroundImageUrl},
    });
});
config.subscribe('denoise', denoise => {
    void mediaService.media.applyConstraints({
        audio: {denoise, noiseSuppression: !denoise},
    });
});
config.subscribe('vad', vad => {
    void mediaService.media.applyConstraints({
        audio: {vad},
    });
});
config.subscribe('asd', asd => {
    void mediaService.media.applyConstraints({
        audio: {asd},
    });
});
config.subscribe('fecc', () => {
    if (!isInitial(mediaService.media.status)) {
        mediaService.getUserMedia(getDefaultConstraints());
    }
});
config.subscribe('bandwidth', () => {
    void mediaService.media.applyConstraints({
        video: {...qualityToMediaConstraints(getStreamQuality())},
    });
});
config.subscribe('presoContentHint', hint => {
    currentDisplayMedia?.getVideoTracks().forEach(applyContentHint(hint));
    const audioContentHint =
        deriveAudioContentHintFromPreso(currentDisplayMedia);
    void mediaService.media.applyConstraints({
        audio: {
            ...deriveAudioFeaturesFromAudioContentHint(audioContentHint),
            contentHint: audioContentHint,
        },
    });
});

endPresentationSignal.add(async () => {
    const contentHint = deriveAudioContentHintFromPreso(undefined);
    await mediaService.media.applyConstraints({
        audio: {
            mixWithAdditionalMedia: false,
            contentHint,
            ...deriveAudioFeaturesFromAudioContentHint(contentHint),
        },
    });
});

export const {useDevices, useLocalMedia, useStreamStatus} =
    generateMediaSignalHooks({
        useDevices: {
            initial: () => mediaService.devices,
            subscribe: mediaSignals.onDevicesChanged.add,
        },

        useLocalMedia: {
            initial: () => mediaService.media,
            subscribe: mediaSignals.onMediaChanged.add,
        },

        useStreamStatus: {
            initial: () => mediaService.media.status,
            subscribe: mediaSignals.onStatusChanged.add,
        },
    });

export const muteAudio = (mute: boolean, persist?: boolean) =>
    config.set({key: 'isAudioInputMuted', value: mute, persist});
export const muteVideo = (mute: boolean, persist?: boolean) =>
    config.set({key: 'isVideoInputMuted', value: mute, persist});
export const toggleAudioMuted = (persist?: boolean) => {
    if (mediaService.media.audioMuted === undefined) {
        return;
    }
    muteAudio(!mediaService.media.audioMuted, persist);
};
export const toggleVideoMuted = (persist?: boolean) => {
    if (mediaService.media.videoMuted === undefined) {
        return;
    }
    muteVideo(!mediaService.media.videoMuted, persist);
};

export const useAudioMuteState = (media: Media) => {
    const [audioMuted, muteAudio] = useState(media.audioMuted);
    const [audioUnavailable, setAudioUnavailable] = useState(
        () => mediaService.media.audioMuted === undefined,
    );

    useEffect(() => {
        if (media.audioMuted === undefined) {
            setAudioUnavailable(true);
        } else {
            setAudioUnavailable(false);
            muteAudio(media.audioMuted);
        }
    }, [media.audioMuted]);

    useEffect(
        () =>
            mediaSignals.onStreamTrackEnabled.add(track => {
                if (track.kind === 'audio') {
                    muteAudio(mediaService.media.audioMuted);
                }
            }),
        [],
    );
    useEffect(
        () =>
            mediaSignals.onStreamTrackMuted.add(track => {
                if (track.kind === 'audio') {
                    muteAudio(mediaService.media.audioMuted);
                    setAudioUnavailable(true);
                }
            }),
        [],
    );
    useEffect(
        () =>
            mediaSignals.onStreamTrackUnmuted.add(track => {
                if (track.kind === 'audio') {
                    muteAudio(mediaService.media.audioMuted);
                    if (track.readyState !== 'ended') {
                        setAudioUnavailable(false);
                    }
                }
            }),
        [],
    );
    useEffect(
        () =>
            mediaSignals.onStreamTrackEnded.add(track => {
                if (track.kind === 'audio') {
                    setAudioUnavailable(true);
                }
            }),
        [],
    );

    return {
        audioMuted: audioUnavailable || audioMuted !== false,
        audioUnavailable,
    };
};

export const useVideoMuteState = (media: Media) => {
    const [videoMuted, muteVideo] = useState(media.videoMuted);
    const [videoUnavailable, setVideoUnavailable] = useState(
        () => mediaService.media.videoMuted === undefined,
    );

    useEffect(() => {
        if (media.videoMuted === undefined) {
            setVideoUnavailable(true);
        } else {
            setVideoUnavailable(false);
            muteVideo(media.videoMuted);
        }
    }, [media.videoMuted]);

    useEffect(
        () =>
            mediaSignals.onStreamTrackEnabled.add(track => {
                if (track.kind === 'video') {
                    muteVideo(mediaService.media.videoMuted);
                }
            }),
        [],
    );
    useEffect(
        () =>
            mediaSignals.onStreamTrackMuted.add(track => {
                if (track.kind === 'video') {
                    muteVideo(mediaService.media.videoMuted);
                    setVideoUnavailable(true);
                }
            }),
        [],
    );
    useEffect(
        () =>
            mediaSignals.onStreamTrackUnmuted.add(track => {
                if (track.kind === 'video') {
                    muteVideo(mediaService.media.videoMuted);
                    if (track.readyState !== 'ended') {
                        setVideoUnavailable(false);
                    }
                }
            }),
        [],
    );
    useEffect(
        () =>
            mediaSignals.onStreamTrackEnded.add(track => {
                if (track.kind === 'video') {
                    setVideoUnavailable(true);
                }
            }),
        [],
    );

    return {
        videoMuted: videoUnavailable || videoMuted !== false,
        videoUnavailable,
    };
};

const previewSegmenter = createSegmenter(taskVisionBasePath, {
    processingWidth: SETTINGS_PROCESSING_WIDTH,
    processingHeight: SETTINGS_PROCESSING_HEIGHT,
    modelAsset,
    delegate,
});

export const releaseSegmenter = () => {
    previewSegmenter.close();
    void previewSegmenter.destroy();
    selfie.close();
    void selfie.destroy();
};

window.addEventListener('beforeunload', releaseSegmenter, {once: true});

export const usePreviewController = createPreviewControllerHook(() => {
    const renderParams = {
        width: SETTINGS_PROCESSING_WIDTH,
        height: SETTINGS_PROCESSING_HEIGHT,
        effects: config.get('segmentationEffects'),
        frameRate: applicationConfig.frameRate,
        backgroundBlurAmount: config.get('backgroundBlurAmount'),
        foregroundThreshold: config.get('foregroundThreshold'),
        edgeBlurAmount: config.get('edgeBlurAmount'),
        backgroundImageUrl: config.get('bgImageUrl'),
        maskCombineRatio: config.get('maskCombineRatio'),
        selfManageSegmenter: supportingVideoProcessing,
        backgroundImage: imageStore.getBitmapRecord(),
    };
    const transformer = createCanvasTransform(previewSegmenter, renderParams);
    const unsubscribe = userCustomImageSignal.add(record => {
        if (!record) {
            return;
        }
        transformer.backgroundImage = imageStore.getBitmapRecord();
    });

    return {
        getCurrentDevices: () => mediaService.devices,
        getCurrentMedia: () => mediaService.media,
        updateMainStream: mediaService.getUserMediaAsync,
        mediaSignal: mediaSignals.onMediaChanged,
        onEnded: () => {
            unsubscribe();
        },
        processors: [
            supportingVideoProcessing &&
                createVideoStreamProcess({
                    trackProcessorAPI: chooseVideoProcessorAPI,
                    shouldEnable: () => applicationConfig.videoProcessing,
                    onError: error => {
                        logger.error({error}, 'Failed to process video');
                    },
                    scope: 'PreviewStreamController',
                    videoSegmentationModel: applicationConfig.segmentationModel,
                    segmenters: {selfie: previewSegmenter},
                    transformer,
                    ...renderParams,
                    processingWidth: renderParams.width,
                    processingHeight: renderParams.height,
                    videoSegmentation: renderParams.effects,
                }),
        ].filter(isMediaProcessor),
    };
});

export const getStreamQuality = (bandwidth = config.get('bandwidth')) => {
    const [low, medium, high, veryHigh] = applicationConfig.bandwidths;

    switch (bandwidth) {
        case low:
            return StreamQuality.Low;
        case medium:
            return StreamQuality.Medium;
        case high:
            return StreamQuality.High;
        case veryHigh:
            return StreamQuality.VeryHigh;
        default:
            return StreamQuality.Auto;
    }
};

export const setStreamQuality = (streamQuality: StreamQuality) => {
    let value;
    const [low, medium, high, veryHigh] = applicationConfig.bandwidths;

    switch (streamQuality) {
        case StreamQuality.Low:
            value = low;
            break;
        case StreamQuality.Medium:
            value = medium;
            break;
        case StreamQuality.High:
            value = high;
            break;
        case StreamQuality.VeryHigh:
            value = veryHigh;
            break;
        default:
            value = '';
            break;
    }

    if (config.get('bandwidth') !== value) {
        config.set({key: 'bandwidth', value, persist: true});
        return true;
    }
    return false;
};

export const usePreviewStreamQuality = createPreviewHook({
    get: getStreamQuality,
    set: setStreamQuality,
});

export const usePreviewDenoise = createPreviewHook({
    get: () => config.get('denoise'),
    set: (value: boolean) => {
        config.set({key: 'denoise', value, persist: true});
        return false;
    },
});

export const usePreviewPresoContentHint = createPreviewHook({
    get: () => config.get('presoContentHint') === VIDEO_CONTENT_HINTS.Motion,
    set: (value: boolean) => {
        config.set({
            key: 'presoContentHint',
            value: value
                ? VIDEO_CONTENT_HINTS.Motion
                : VIDEO_CONTENT_HINTS.Detail,
            persist: true,
        });
        return false;
    },
});

export const usePreviewFecc = createPreviewHook({
    get: () => config.get('fecc'),
    set: (value: boolean) => {
        if (config.get('fecc') !== value) {
            config.set({key: 'fecc', value, persist: true});
            return true;
        }
        return false;
    },
});

export const usePreviewPreferPrexInMix = createPreviewHook({
    get: () => config.get('preferPresInMix'),
    set: (value: boolean) => {
        config.set({key: 'preferPresInMix', value, persist: true});
        return false;
    },
});

export const usePreviewSegmentationEffects = createPreviewHook({
    get: () => config.get('segmentationEffects'),
    set: (value: RenderEffects) => {
        config.set({key: 'segmentationEffects', value, persist: true});
        return false;
    },
});

export const usePreviewBgImageUrl = createPreviewHook({
    get: () => config.get('bgImageUrl'),
    set: (value: string) => {
        config.set({key: 'bgImageUrl', value, persist: true});
        return false;
    },
});

export const usePreviewAudioOutput = createPreviewAudioOutputHook(
    (value: MediaDeviceInfoLike) =>
        config.set({key: 'audioOutput', value, persist: true}),
);

export const usePreviewAudioInput = createPreviewAudioInputHook({
    get: () => config.get('audioInput'),
    getExpected: () => mediaService.media.expectedAudioInput,
    set: (value?: MediaDeviceInfoLike) =>
        value &&
        config.set({
            key: 'audioInput',
            value: toMediaDeviceInfo(value),
            persist: true,
        }),
});

export const usePreviewVideoInput = createPreviewVideoInputHook({
    get: () => config.get('videoInput'),
    getExpected: () => mediaService.media.expectedVideoInput,
    set: (value?: MediaDeviceInfoLike) =>
        value &&
        config.set({
            key: 'videoInput',
            value: toMediaDeviceInfo(value),
            persist: true,
        }),
});

export const enableAudioSignalDetection = () =>
    config.set({key: 'asd', value: true});

export const disableAudioSignalDetection = () =>
    config.set({key: 'asd', value: false});

let cacheFacingModeToggleDetected = false;
export const canShowFacingModeToggle = (
    devices: MediaDeviceInfoLike[],
): boolean => {
    if (cacheFacingModeToggleDetected) {
        return cacheFacingModeToggleDetected;
    }
    cacheFacingModeToggleDetected =
        areMultipleFacingModeSupported(devices) ||
        // Safari translates the device label which makes it unreliable to snoop the label
        currentBrowserName === 'Safari iPad' ||
        currentBrowserName === 'Safari iPhone';
    return cacheFacingModeToggleDetected;
};

export const toggleFacingMode = (track: MediaStreamTrack | undefined) => {
    // FIXME: you probably shouldn't set it in the first place but we do in express flow on mobile
    config.set({
        key: 'videoInput',
        value: undefined,
    });
    const currentFacingMode =
        interpretCurrentFacingMode(track) ??
        (config.get('isUserFacing') ? 'user' : 'environment');
    const isUserFacing = !isUserFacingMode(currentFacingMode);
    config.set({
        key: 'isUserFacing',
        value: isUserFacing,
        persist: true,
    });
    // Do not send a mute request to backend if the user is toggling the camera
    mediaService.getUserMedia({
        audio: true,
        video: {
            facingMode: {
                ideal: getFacingMode(isUserFacing),
            },
        },
    });
};
