import type {CSSProperties, ReactElement} from 'react';
import type {Key} from '@react-types/shared';
import {Item} from '@react-stately/collections';
import React from 'react';
import type {Placement} from '@react-aria/overlays';

import type {Button} from '../../elements/Button/Button';
import type {Icon} from '../../elements/Icon/Icon';
import type {ColorScheme} from '../../../types/variants';

import {InnerAriaMenuButton} from './InnerAriaMenuButton';

/**
 * The structure of a menu item that can be used to create a dropdown menu.
 */
export type AriaMenuItem = {
    /**
     * The unique key for the item.
     */
    key: string;
    /**
     * The label of the item.
     */
    label: string;
    /**
     * The test id for the item.
     */
    testId?: string;
    /**
     *  If set, this function will be called when the user clicks this item.
     */
    onItemClick?: () => void;
    /**
     * If set to false, the menu will not close when the user clicks this item.
     */
    closeMenuOnItemClick?: boolean;
    /**
     * The icon to display next to the item.
     */
    icon?: ReactElement<typeof Icon>;
    /**
     * Class name that is used to override the default hover style.
     */
    alternativeHoverClassName?: string;
    /**
     * Style that is used to override the default hover style.
     */
    alternativeHoverStyle?: CSSProperties;
    /**
     * The class name for the item.
     */
    className?: string;
    /**
     * The style for the item.
     */
    style?: CSSProperties;
};

/**
 * AriaMenu component represents a dropdown menu.
 */
export const AriaMenu: React.FC<{
    /**
     * An array of menu items.
     */
    items: AriaMenuItem[];
    /**
     * The button component that triggers the menu.
     */
    button: ReactElement<typeof Button>;
    /**
     * Optional content to show above the menu items.
     */
    headerContent?: ReactElement;
    /**
     * Callback function called when the menu open state changes.
     * @param isOpen - The new open state of the menu.
     */
    onOpenChange?: (isOpen: boolean) => void;

    /**
     * The test id for the menu.
     */
    testId?: string;
    /**
     * The color scheme for the menu.
     */
    colorScheme?: ColorScheme;
    /**
     * The placement of the popover.
     */
    popoverPlacement?: Placement;

    /**
     * The offset for the popover.
     */
    popoverOffset?: number;
}> = ({
    items,
    button,
    headerContent,
    onOpenChange,
    testId,
    colorScheme,
    popoverPlacement,
    popoverOffset,
}) => {
    const itemMap: Map<Key, AriaMenuItem> = new Map();
    items.map(item => itemMap.set(item.key, item));
    return (
        <InnerAriaMenuButton
            button={button}
            headerContent={headerContent}
            onOpenChange={onOpenChange}
            itemMap={itemMap}
            testId={testId}
            colorScheme={colorScheme}
            popoverPlacement={popoverPlacement}
            popoverOffset={popoverOffset}
        >
            {items.map(item => (
                <Item key={item.key}>{item.label}</Item>
            ))}
        </InnerAriaMenuButton>
    );
};
