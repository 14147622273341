import React, {useState} from 'react';
import cx from 'classnames';

import {useFocusFromOpened} from '@pexip/components';

import {TestId} from '../..';

import styles from './ChatPanelContentWrapper.module.scss';

export const ChatPanelContentWrapper: React.FC<{
    children: React.ReactNode;
    className?: string;
    isShown: boolean;
    animateIn?: boolean;
    animateOut?: boolean;
}> = ({children, className, isShown, animateIn, animateOut}) => {
    const [containerElement, setContainerElement] =
        useState<HTMLDivElement | null>(null);
    useFocusFromOpened(containerElement, isShown);

    return (
        <div
            data-testid={TestId.ChatPanel}
            ref={setContainerElement}
            className={cx(
                styles.panel,
                styles.wrapper,
                {
                    [styles.show]: isShown,
                    [styles.animateIn]: !!animateIn,
                    [styles.animateOut]: !!animateOut,
                },
                className,
            )}
        >
            {children}
        </div>
    );
};
