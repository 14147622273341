import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import type {MenuItem, Button, AriaMenuItem} from '@pexip/components';
import {AriaMenu, Color, Icon, IconTypes} from '@pexip/components';

import type {InMeetingParticipant} from '../../types';
import {TestId} from '../../../test/testIds';
import {ParticipantRowMoreActionsTouchModal} from '../ParticipantRowMoreActionsTouchModal/ParticipantRowMoreActionsTouchModal.view';
import {ParticipantRowMoreActionsHeader} from '../ParticipantRowMoreActionsHeader/ParticipantRowMoreActionsHeader.view';

export const ParticipantRowMoreActions: React.FC<{
    isOpen: boolean;
    isTouchDevice: boolean;
    showParticipantAuthenticatedState: boolean;
    displayName: string;
    handleTransfer?: (identity: string) => void;
    handleDTMF?: (identity: string) => void;
    handleFecc?: (identity: string) => void;
    handleRemoveUserRequest: (
        participant: InMeetingParticipant,
        displayName: string,
    ) => void;
    shouldShowHostActions: boolean;
    shouldShowHostActionsForCurrentPeer: boolean;
    isMuted: boolean;
    isDirectMedia?: boolean;
    isCurrentPeer: boolean;
    isSpotlight?: boolean;
    participant: InMeetingParticipant;
    additionalMenuContent: MenuItem[];
    button: React.ReactElement<typeof Button>;
    onOpenChange: (state: boolean) => void;
}> = ({
    isOpen,
    isTouchDevice,
    showParticipantAuthenticatedState,
    displayName,
    handleTransfer,
    handleDTMF,
    handleFecc,
    handleRemoveUserRequest,
    shouldShowHostActions,
    shouldShowHostActionsForCurrentPeer,
    isMuted,
    isDirectMedia,
    isCurrentPeer,
    isSpotlight,
    participant,
    additionalMenuContent,
    button,
    onOpenChange,
}) => {
    const {t} = useTranslation();

    const menuContent: AriaMenuItem[] = useMemo(() => {
        return [
            (shouldShowHostActions ||
                (isMuted && shouldShowHostActionsForCurrentPeer)) &&
                participant.canMute &&
                ({
                    label: isMuted
                        ? t('media.unmute-microphone', 'Unmute microphone')
                        : t('media.mute-microphone', 'Mute microphone'),
                    onItemClick: participant.mute,
                    testId: isMuted
                        ? TestId.ActionUnmuteMicrophone
                        : TestId.ActionMuteMicrophone,
                    key: 'microphone',
                    icon: (
                        <Icon
                            size={isTouchDevice ? 'small' : 'compact'}
                            source={
                                isMuted
                                    ? IconTypes.IconMicrophoneOff
                                    : IconTypes.IconMicrophoneOn
                            }
                        />
                    ),
                } as AriaMenuItem),
            !isDirectMedia &&
                Boolean(participant.spotlight) &&
                participant.canSpotlight &&
                ({
                    label: isSpotlight
                        ? t('media.remove-spotlight', 'Remove spotlight')
                        : t('media.spotlight', 'Spotlight'),
                    onItemClick: () =>
                        participant.spotlight && participant.spotlight(),
                    testId: isSpotlight
                        ? TestId.ActionRemoveSpotlight
                        : TestId.ActionSpotlight,
                    closeMenuOnItemClick: false,
                    key: 'spotlight',
                    icon: (
                        <Icon
                            size={isTouchDevice ? 'small' : 'compact'}
                            source={IconTypes.IconSpotlight}
                        />
                    ),
                } as AriaMenuItem),
            Boolean(handleTransfer) &&
                participant.canTransfer &&
                ({
                    label: t('media.transfer', 'Transfer'),
                    onItemClick: () => handleTransfer?.(participant.identity),
                    testId: TestId.ActionTransfer,
                    key: 'transfer',
                    icon: (
                        <Icon
                            size={isTouchDevice ? 'small' : 'compact'}
                            source={IconTypes.IconArrowCornerDown}
                        />
                    ),
                } as AriaMenuItem),
            Boolean(participant.setRole && !isCurrentPeer) &&
                !participant.isExternal &&
                ({
                    label: participant.isHost
                        ? t('meeting.make-guest', 'Make guest')
                        : t('meeting.make-host', 'Make host'),
                    onItemClick: () =>
                        participant.setRole && participant.setRole(),
                    testId: participant.isHost
                        ? TestId.ActionMakeGuest
                        : TestId.ActionMakeHost,
                    key: 'setRole',
                    closeMenuOnItemClick: false,
                    icon: (
                        <Icon
                            size={isTouchDevice ? 'small' : 'compact'}
                            source={
                                participant.isHost
                                    ? IconTypes.IconGuest
                                    : IconTypes.IconHost
                            }
                        />
                    ),
                } as AriaMenuItem),
            !isDirectMedia &&
                Boolean(handleDTMF) &&
                !participant.isConnecting &&
                ({
                    label: t('dtmf.enter-dtmf', 'Enter DTMF'),
                    onItemClick: () => handleDTMF?.(participant.identity),
                    testId: TestId.ActionEnterDTMF,
                    key: 'dtmf',
                    icon: (
                        <Icon
                            size={isTouchDevice ? 'small' : 'compact'}
                            source={IconTypes.IconDtmf}
                        />
                    ),
                } as AriaMenuItem),
            Boolean(handleFecc) &&
                participant.canFecc &&
                ({
                    label: t('fecc.camera-control', 'Camera control'),
                    onItemClick: () =>
                        handleFecc && handleFecc(participant.identity),
                    testId: TestId.ActionFecc,
                    key: 'fecc',
                    icon: (
                        <Icon
                            size={isTouchDevice ? 'small' : 'compact'}
                            source={IconTypes.IconFecc}
                        />
                    ),
                } as AriaMenuItem),
            Boolean(participant.lowerHand) &&
                !isCurrentPeer &&
                participant.canRaiseHand &&
                participant.raisedHand && {
                    label: t('meeting.lower-hand', 'Lower hand'),
                    onItemClick: () =>
                        participant.lowerHand && participant.lowerHand(),
                    testId: TestId.ActionLowerHand,
                    key: 'lowerHand',
                    closeMenuOnItemClick: false,
                    icon: (
                        <Icon
                            size={isTouchDevice ? 'small' : 'compact'}
                            source={IconTypes.IconLowerhand}
                        />
                    ),
                },
            shouldShowHostActions &&
                participant.canDisconnect && {
                    label: t('meeting.disconnect', 'Disconnect'),
                    onItemClick: () =>
                        handleRemoveUserRequest(participant, displayName),
                    testId: TestId.ButtonKickOffParticipant,
                    key: 'disconnect',
                    icon: (
                        <Icon
                            size={isTouchDevice ? 'small' : 'compact'}
                            source={IconTypes.IconPhoneHorisontal}
                        />
                    ),
                    style: {color: Color.Red70},
                },
            ...additionalMenuContent.map(item => ({
                label: item.label,
                onItemClick: item.clickAction,
                key: item.label,
            })),
        ].filter(Boolean) as AriaMenuItem[];
    }, [
        isDirectMedia,
        participant,
        isSpotlight,
        t,
        handleTransfer,
        isCurrentPeer,
        handleDTMF,
        handleFecc,
        additionalMenuContent,
        displayName,
        handleRemoveUserRequest,
        shouldShowHostActions,
        shouldShowHostActionsForCurrentPeer,
        isMuted,
        isTouchDevice,
    ]);

    return isTouchDevice ? (
        <ParticipantRowMoreActionsTouchModal
            showParticipantAuthenticatedState={
                showParticipantAuthenticatedState
            }
            isAuthenticated={!!participant.isIdpAuthenticated}
            displayName={displayName}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            menuContent={menuContent}
            button={button}
        />
    ) : (
        <AriaMenu
            headerContent={
                <ParticipantRowMoreActionsHeader
                    isTouchDevice={false}
                    className="pt-4 pl-4 pr-4 pb-1"
                    displayName={displayName}
                    isAuthenticated={!!participant.isIdpAuthenticated}
                    showParticipantAuthenticatedState={
                        showParticipantAuthenticatedState
                    }
                />
            }
            onOpenChange={onOpenChange}
            button={button}
            items={menuContent}
            testId="kebab-menu"
        />
    );
};
