import type {ComponentProps} from 'react';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import {
    Button,
    Color,
    FontVariant,
    Icon,
    IconTypes,
    Text,
    Tooltip,
} from '@pexip/components';
import type {MenuItem} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import type {InMeetingParticipant} from '../../types';
import {ParticipantRowActions} from '../ParticipantRowActions/ParticipantRowActions.view';
import type {RowActionState} from '../ParticipantRowActions/ParticipantRowActions.module';
import {BreakoutParticipantRowActions} from '../ParticipantRowActions/BreakoutParticipantRowActions.view';

import {MultiCallList} from './MultiCallList.view';

import styles from './ParticipantRow.module.scss';

const ParticipantAdmit: React.FC<{
    isTouchDevice: boolean;
    admit?: () => void;
}> = ({isTouchDevice, admit}) => {
    const {t} = useTranslation();
    const [isLoading, setLoading] = useState(false);

    const handleAdmit = () => {
        setLoading(true);
        admit?.();
    };

    return (
        <Tooltip text={t('meeting.admit', 'Admit')} position="top">
            <Button
                aria-label={t('meeting.admit-participant', 'Admit participant')}
                noEnhancerPadding
                onClick={handleAdmit}
                variant="text"
                isLoading={isLoading}
                isDisabled={isLoading}
                size={isTouchDevice ? 'small' : 'compact'}
                data-testid={TestId.ButtonAdmitParticipant}
            >
                <Icon
                    source={IconTypes.IconCheckmark}
                    color={Color.Join}
                    size={isTouchDevice ? 'small' : 'compact'}
                />
            </Button>
        </Tooltip>
    );
};

const ParticipantDeny: React.FC<{
    isTouchDevice: boolean;
    kick?: () => void;
}> = ({isTouchDevice, kick}) => {
    const {t} = useTranslation();
    const [isLoading, setLoading] = useState(false);

    const handleKick = () => {
        setLoading(true);
        kick?.();
    };

    return (
        <Tooltip text={t('meeting.deny', 'Deny')} position="top">
            <Button
                aria-label={t('meeting.deny-participant', 'Deny participant')}
                noEnhancerPadding
                onClick={handleKick}
                variant="text"
                isLoading={isLoading}
                isDisabled={isLoading}
                size={isTouchDevice ? 'small' : 'compact'}
                data-testid={TestId.ButtonDenyParticipant}
            >
                <Icon
                    source={IconTypes.IconClose}
                    color={Color.Red70}
                    size={isTouchDevice ? 'small' : 'compact'}
                />
            </Button>
        </Tooltip>
    );
};

export const ParticipantRowWrapper: React.FC<
    ComponentProps<'div'> & {
        isBreakout?: boolean;
        showParticipantAuthenticatedState?: boolean;
        participant: InMeetingParticipant;
        isCurrentPeer?: boolean;
        onMouseEnter: () => void;
        onMouseLeave: () => void;
    }
> = ({
    isBreakout = false,
    showParticipantAuthenticatedState = false,
    participant,
    isCurrentPeer,
    onMouseEnter,
    onMouseLeave,
    onPointerDownCapture,
    children,
    className,
}) => {
    const {t} = useTranslation();
    return (
        <div
            key={participant.identity}
            className={cx(styles.participantRow, className)}
            role="region"
            aria-label={t('meeting.participant-row-aria-label', {
                defaultValue: 'Participant - {{name}}',
                name: participant.displayName ?? '',
            })}
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- needs to be tabbable
            tabIndex={0}
            onMouseEnter={onMouseEnter}
            onFocus={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onBlur={e => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    onMouseLeave();
                }
            }}
            data-testid={TestId.ParticipantRow}
            onPointerDownCapture={onPointerDownCapture}
        >
            <div className={styles.participant}>
                {!isBreakout && showParticipantAuthenticatedState && (
                    <Tooltip
                        text={
                            participant.isIdpAuthenticated
                                ? t('meeting.authenticated', 'Authenticated')
                                : t(
                                      'meeting.unauthenticated',
                                      'Unauthenticated',
                                  )
                        }
                        position="topRight"
                    >
                        <Icon
                            className="mr-1"
                            source={
                                participant.isIdpAuthenticated
                                    ? IconTypes.IconCheckmarkRound
                                    : IconTypes.IconAlert
                            }
                            size="compact"
                            color={
                                participant.isIdpAuthenticated
                                    ? Color.Join
                                    : Color.Red70
                            }
                        />
                    </Tooltip>
                )}
                <Text
                    fontVariant={FontVariant.Body}
                    isTruncated
                    title={participant.displayName}
                    translate="no"
                >
                    {participant.displayName || 'User'}
                </Text>
                {isCurrentPeer && (
                    <Text
                        className={styles.metaText}
                        fontVariant={FontVariant.Body}
                        title="You"
                        translate="no"
                    >
                        {'/ '}
                        {t('meeting.you', 'You')}
                    </Text>
                )}
                {participant.isHost && (
                    <Text
                        className={styles.metaText}
                        fontVariant={FontVariant.Body}
                        title="Host"
                        translate="no"
                    >
                        {'/ '}
                        {t('common.host', 'Host')}
                    </Text>
                )}
            </div>
            <div className={styles.actionsWrapper}>
                <div className={styles.scrim} />
                {children}
            </div>
        </div>
    );
};

export const BreakoutParticipantRow: React.FC<
    ComponentProps<'div'> &
        Pick<
            ComponentProps<typeof ParticipantRowWrapper>,
            'participant' | 'isCurrentPeer'
        > & {
            onParticipantRoomChange?: (
                targetParticipantUuid: string,
                targetRoomId: string,
            ) => void;
            rooms?: Record<string, string>;
            isDragging: boolean;
        }
> = ({
    participant,
    isCurrentPeer,
    rooms,
    onParticipantRoomChange,
    isDragging,
    className,
}) => {
    const [isHover, setIsHover] = useState(false);

    const onMouseEnter = useCallback(() => setIsHover(true), []);
    const onMouseLeave = useCallback(() => setIsHover(false), []);

    const onRoomChange = useCallback(
        (roomId: string) => {
            onParticipantRoomChange?.(participant.identity, roomId);
        },
        [onParticipantRoomChange, participant.identity],
    );

    return (
        <ParticipantRowWrapper
            isBreakout
            participant={participant}
            isCurrentPeer={isCurrentPeer}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={className}
        >
            {rooms && Object.entries(rooms).length > 0 && (
                <BreakoutParticipantRowActions
                    rooms={rooms}
                    onRoomChange={onRoomChange}
                    isHover={isHover}
                    isDragging={isDragging}
                />
            )}
        </ParticipantRowWrapper>
    );
};

export const ParticipantRow: React.FC<
    Pick<RowActionState, 'isSpeaking'> & {
        isTouchDevice: boolean;
        isDirectMedia?: boolean;
        displayName: string;
        handleRemoveUserRequest: (
            participant: InMeetingParticipant,
            displayName: string,
        ) => void;
        handleTransfer?: (identity: string) => void;
        handleDTMF?: (identity: string) => void;
        handleFecc?: (identity: string) => void;
        isCurrentPeer: boolean;
        canPerformHostActions?: boolean;
        isHost: boolean;
        participant: InMeetingParticipant;
        additionalMenuContent: MenuItem[];
    } & Pick<
            ComponentProps<typeof ParticipantRowWrapper>,
            'participant' | 'showParticipantAuthenticatedState'
        >
> = ({
    isTouchDevice,
    isDirectMedia,
    showParticipantAuthenticatedState,
    displayName,
    handleRemoveUserRequest,
    handleTransfer,
    handleDTMF,
    handleFecc,
    isCurrentPeer,
    canPerformHostActions = true,
    isHost,
    isSpeaking,
    participant,
    additionalMenuContent,
}) => {
    const [isHover, setIsHover] = useState(false);

    const onMouseEnter = useCallback(() => setIsHover(true), []);
    const onMouseLeave = useCallback(() => setIsHover(false), []);

    const {calls} = participant;

    const shouldDisplayChildCalls = useMemo(
        () => Boolean(calls && calls?.length > 1),
        [calls],
    );

    return (
        <div>
            <ParticipantRowWrapper
                participant={participant}
                showParticipantAuthenticatedState={
                    showParticipantAuthenticatedState
                }
                isCurrentPeer={isCurrentPeer}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {participant.participantType === 'admit' ? (
                    <>
                        <ParticipantAdmit
                            isTouchDevice={isTouchDevice}
                            admit={participant?.admit}
                        />
                        <ParticipantDeny
                            isTouchDevice={isTouchDevice}
                            kick={participant?.kick}
                        />
                    </>
                ) : (
                    <ParticipantRowActions
                        isTouchDevice={isTouchDevice}
                        showParticipantAuthenticatedState={
                            !!showParticipantAuthenticatedState
                        }
                        participant={participant}
                        isDirectMedia={isDirectMedia}
                        canPerformHostActions={canPerformHostActions}
                        isHost={isHost}
                        isSpeaking={
                            shouldDisplayChildCalls ? false : isSpeaking
                        }
                        isHover={isHover}
                        isCurrentPeer={isCurrentPeer}
                        handleRemoveUserRequest={handleRemoveUserRequest}
                        handleTransfer={handleTransfer}
                        handleDTMF={handleDTMF}
                        handleFecc={handleFecc}
                        displayName={displayName}
                        hasChildCalls={shouldDisplayChildCalls}
                        onMouseLeave={onMouseLeave}
                        additionalMenuContent={additionalMenuContent}
                    />
                )}
            </ParticipantRowWrapper>
            {shouldDisplayChildCalls && (
                <MultiCallList calls={participant.calls} />
            )}
        </div>
    );
};
export type ParticipantRowProps = React.ComponentProps<typeof ParticipantRow>;
